
import moment from 'moment'
import Vue, { PropType } from 'vue'
import api from '../../api/api'

import Breadcrumbs from '../../components/Breadcrumbs.vue'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { Connector, Project } from '../../types/main'
import { CONNECTOR_TYPES } from '../../vars/general'
import BaseInputOutlined from '../../components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../components/inputs/CustomActivatorSelect.vue'
import _ from 'lodash'
import { numSort, floatNumSort } from '../../utils'
import Select from '../../components/inputs/Select.vue'
import { toPercent } from '@root/src/components/chisai/GCB2/helpers'
import PhoneLink from '@root/src/components/iconLinks/PhoneLink.vue'
import messageVarsService from '@root/src/helpers/gcb2/messageVars'
import AmoCrmLink from '@root/src/components/iconLinks/AmoCrmLink.vue'
import YClientsLink from '@root/src/components/iconLinks/YClientsLink.vue'
import ProfSalonLink from '@root/src/components/iconLinks/ProfSalonLink.vue'
import SonlineLink from '@root/src/components/iconLinks/SonlineLink.vue'
import AltegioLink from '@root/src/components/iconLinks/AltegioLink.vue'
import DikidiLink from '@root/src/components/iconLinks/DikidiLink.vue'
import ChatLink from '@root/src/components/iconLinks/ChatLink.vue'
import VideoHelper from '@root/src/components/VideoHelper.vue'

export default Vue.extend({
    name: 'GCB2MarketingReport',
    components: {
        Breadcrumbs,
        SpinnerLoader,
        CustomActivatorSelect,
        BaseInputOutlined,
        Select,
        AmoCrmLink,
        YClientsLink,
        ProfSalonLink,
        SonlineLink,
        AltegioLink,
        DikidiLink,
        ChatLink,
        PhoneLink,
        VideoHelper
    },

    data: () => ({
        pageLoading: false,
        dashboardDataLoading: true,
        dateMenu: false,
        dates: [] as string[],
        staffNames: [] as string[],
        staffNamesList: [] as string[],
        dateFormat: 'DD.MM.YYYY',
        datePickerDateFormat: 'YYYY-MM-DD',
        filters: { productsDisplay: 0 } as any,
        filtersLists: {} as any,
        inititalFilters: {},
        projectType: null as any,
        expandedAdminsDay: [],
        adminsEffortsData: [],
        detailedTableData: [],
        initialDetailedRecordTable: [],
        initialAdminsEfforts: [],
        networkId: '',
        statByDateDataHeaders: [
            { text: 'День', value: 'visitDate' },
            { text: 'Количество клиентов ', value: 'clientCount', sort: numSort },
            { text: 'Клиентов перезаписано', value: 'recordedOtherDay', sort: numSort },
            { text: 'Процент перезаписи', value: 'recordedOtherDayProportion', sort: floatNumSort },
        ],
        detailTableHeaders: [
            { text: 'Имя клиента', value: 'clientName', width: '19%' },
            { text: 'Имя администратора', value: 'adminName', width: '330px' },
            { text: 'Дата визита', value: 'visitDate'},
            { text: 'Запись онлайн', value: 'isOnline', width: '16%' },
            { text: '', value: 'action', sortable: false, },
        ],
        adminsEffortsTableHeaders: [
            { text: 'День', value: 'visitDate' },
            { text: 'Кол-во администраторов', value: 'numAdmDay', sort: numSort },
            { text: 'Имя администратора', value: 'attrUserName' },
            { text: 'Процент усилия', value: 'effortsAdmins',  sort: floatNumSort},
        ],
        adminsDayTableHeaders: [
            { text: 'День', value: 'visitDate' },
            { text: 'Кол-во клиентов', value: 'clientCount' },
            { text: 'Клиентов перезаписано', value: 'recordedOtherDay' },
            { text: 'Процент перезаписи', value: 'recordedOtherDayProportion' },
            { text: 'Процент усилия администратора', value: 'effortsAdmins' },
        ],
        dashboardData: {
            statByStaffData: [],
        } as any,
        showVideoHelp: true
    }),
    watch: {},
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        groupedAdminsDays() {
            const formatNumber = (num: number) => new Intl.NumberFormat('ru-RU').format(num)
            const grouped = this.dashboardData?.adminsDayTable?.reduce((acc, item) => {
                const key = item.user_id

                if (!acc[key]) {
                    acc[key] = {
                        groupId: key,
                        groupName: item.user_name,
                        data: [],
                    }
                }
                const entry = {
                    visitDate: item.visit_date,
                    clientCount: formatNumber(item.client_count ?? 0),
                    recordedOtherDay: formatNumber(item.recorded_other_day ?? 0),
                    recordedOtherDayProportion: `${formatNumber(item.recorded_other_day_proportion ?? 0)}%`,
                    effortsAdmins: `${formatNumber(item.efforts_admins ?? 0)}%`,
                }
                acc[key].data.push(entry)

                return acc
            }, {})
            if (grouped) {
                const result: any[] = Object.values(grouped)
                return result
            }
        },
        summaryRowStatByDateData() {
            const formatNumber = (num: number) => new Intl.NumberFormat('ru-RU').format(num)
            let summaryClientCount = 0
            let summaryRecordedOtherDay = 0
            this.dashboardData?.statByDateData?.forEach(el => {
                summaryClientCount += el.client_count || 0
                summaryRecordedOtherDay +=el.recorded_other_day
            })
            let summaryRecordedOtherDayProportion = Number((summaryRecordedOtherDay / summaryClientCount).toFixed(2))
            if (summaryRecordedOtherDayProportion > 1) {
                summaryRecordedOtherDayProportion = 1
            }
            return {
                visitDate: 'Общий итог:',
                clientCount: formatNumber(summaryClientCount ?? 0),
                recordedOtherDay: formatNumber(summaryRecordedOtherDay ?? 0),
                recordedOtherDayProportion: `${toPercent(summaryRecordedOtherDayProportion)}`,
            }
        },
        projectIsNetwork(): boolean {
            return this.projectType === 'network'
        },
        connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        yClientsConnector(): any {
            return this.$store.getters
                .projectById(this.$router.currentRoute.params.id)
                .connectors.find((el: Connector) => el.connectorType === CONNECTOR_TYPES.yClients)
        },
        datailedTableDiff(): any [] {
            let diffArr = []
            const mapedDetailedRecordTableFromReq = this.initialDetailedRecordTable?.map((item: any) => {
                let clientName= item.client_name_with_num.split(' ')[0]
                return {
                    clientName,
                    phone: item.client_phone,
                    visitDate: item.visit_date,
                    isOnline: item.is_online,
                    adminName: item.user_name,
                    hrefInfo: item.hrefInfo,
                    clientId: item.client_id,
                    userId: item.user_id,
                    userName: item.user_name,
                    originalProjectId: item.original_project_id,
                    creatingUserIdYc: item.creating_user_id_yc
                }
            })
            if (this.detailedTableData && mapedDetailedRecordTableFromReq) {
                diffArr = _.differenceBy(this.detailedTableData, mapedDetailedRecordTableFromReq, 'userId')
            }
            return diffArr
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'ОПЗ',
                    to: '',
                    disabled: true,
                },
            ]
        },
        dateRangeText(): string {
            const formatedDates = this.sortedDates.map(date =>
                moment(date, this.datePickerDateFormat).format(this.dateFormat)
            )
            return formatedDates.join(' - ')
        },
        sortedDates(): string[] {
            return [
                ...this.dates.sort(
                    (a, b) =>
                        moment(a, this.datePickerDateFormat).unix() -
                        moment(b, this.datePickerDateFormat).unix()
                ),
            ]
        },
        dateBorders(): { to: string; from: string } {
            return {
                from: this.sortedDates[0],
                to: this.sortedDates[1],
            }
        },
        displayFilters(): any {
            return {
                filialList: Object.values(this.filters.filialList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.id),
            }
        },
        queryFilters(): any {
            return _.pickBy(
                {
                    dateFrom: this.sortedDates[0],
                    dateTo: this.sortedDates[1],
                    filialList: this.projectIsNetwork ? this.filters.filialList : undefined,
                },
                (v, k) => {
                    if (_.isObject(v) && _.isEmpty(v)) {
                        return false
                    }
                    if (
                        !_.isNil(v) &&
                        this.displayFilters[k] &&
                        (this.filtersLists[k].length === this.displayFilters[k].length ||
                            this.displayFilters[k].length === 0)
                    ) {
                        return false
                    }
                    return true
                }
            )
        },
        statByDateData(): any[] {
            const formatNumber = (num: number) => new Intl.NumberFormat('ru-RU').format(num)
            return this.dashboardData?.statByDateData?.map((el: any) => ({
                visitDate: el.visit_date,
                clientCount: formatNumber(el.client_count ?? 0),
                recordedOtherDay: formatNumber(el.recorded_other_day ?? 0),
                recordedOtherDayProportion: `${formatNumber(el.recorded_other_day_proportion ?? 0)}%`,
            }))
        },
        adminsEffortsDiff(): any [] {
            let diffArr = []
                const formatNumber = (num: number) => new Intl.NumberFormat('ru-RU').format(num)
                const mapedAdminsEffortsFromReq = this.initialAdminsEfforts?.map((el: any) => ({
                    visitDate: el.visit_date,
                    numAdmDay: formatNumber(el.num_adm_day ?? 0),
                    attrUserName: el.attr_user_name,
                    effortsAdmins: formatNumber(el.efforts_admins ?? 0),
                    originalProjectId: el.original_project_id,
                    creatingUserIdYc: el.creating_user_id_yc,
                    attrUserId: el.attr_user_id,
                }))
                const adminsEffortsData = _.cloneDeep(this.adminsEffortsData)
                if (this.adminsEffortsData && mapedAdminsEffortsFromReq) {
                    diffArr = _.differenceBy(adminsEffortsData, mapedAdminsEffortsFromReq, 'effortsAdmins')
                }
            return diffArr
        },
        showDiffHint(): boolean {
            return this.yClientsConnector && !this.dashboardDataLoading && (this.adminsEffortsDiff.length > 0 || this.datailedTableDiff.length > 0)
        },
        admins() {
            const projectId = this.$router.currentRoute.params.id
            const project = this.$store.getters.projectById(projectId) as Project
            let users = project.users
            let filterdUsers = users.filter(item => item.role.id !== 'observer').map(item => {
                return {
                    name: item.name,
                    id: item.id
                }
            })
            filterdUsers.push({name: 'Нет данных об администраторе', id: 'Нет данных об администраторе'})
            return filterdUsers
        },
    },
    methods: {
        setFiltersLists() {
            this.filtersLists = {
                filialList: Object.values(this.filters.filialList ?? {}).map((el: any) => ({
                    id: el.id,
                    name: el.option,
                })),
            }
        },
        setDefaultDates() {
            this.dates = [
                moment()
                    .subtract(60, 'days')
                    .format(this.datePickerDateFormat),
                moment()
                    .subtract(30, 'days')
                    .format(this.datePickerDateFormat),
            ]
        },

        dropFilters() {
            this.setDefaultDates()
            this.filters = Object.assign({}, this.inititalFilters)
            this.initData(true)
        },
        setFilterValue(filterId: string, val: any) {
            const updatedFilterVal = {}
            const selectedHmap = val.reduce((a: any, c: any) => Object.assign(a, { [c]: true }), {})
            this.filtersLists[filterId].forEach((el: any) => {
                _.setWith(
                    updatedFilterVal,
                    [el.id],
                    {
                        id: el.id,
                        option: el.name,
                        selected: Boolean(selectedHmap[el.id]),
                        service_group: el.service_group,
                    },
                    Object
                )
            })
            this.filters = Object.assign({}, this.filters, { [filterId]: updatedFilterVal })
        },
        async initData(reset: boolean) {
            this.dashboardDataLoading = true
            let data
            let error
            if (this.yClientsConnector) {
                const adminsEffortsDiff = _.cloneDeep(this.adminsEffortsDiff)
                if (adminsEffortsDiff.length > 0 && !reset) {
                    const settings = adminsEffortsDiff.map(el => {
                        return {
                            networkId: this.networkId,
                            originalProjectId: el.originalProjectId,
                            workDate: el.visitDate,
                            creatingUserIdYc: el.creatingUserIdYc,
                            userId: el.attrUserId,
                            userName: el.attrUserName,
                            effortsAdmins: el.effortsAdmins
                        }
                    })
                    const resEffortsSettings = await api.gcb2.saveOpzAdminsEffortSettings({
                        settings
                    })
                }
                const detailedTableDiff = _.cloneDeep(this.datailedTableDiff)
                if (detailedTableDiff.length > 0 && !reset) {
                    const settings = detailedTableDiff.map(el => {
                        return {
                            networkId: this.networkId,
                            clientId: el.clientId,
                            originalProjectId: el.originalProjectId,
                            visitDate: el.visitDate,
                            creatingUserIdYc: el.creatingUserIdYc,
                            userId: el.userId.id,
                            userName: el.userId.name,
                        }
                    })
                    const resDetailedTableSettings = await api.gcb2.saveOpzChangeSettings({
                        settings
                    })
                }
                const res = await api.gcb2.newSameDayRecReportData({
                    projectId: this.project.id,
                    filters: this.queryFilters,
                })
                data = res.data
                error = res.error
                this.networkId = data.network_id
                const newData = Object.assign(
                    {},
                    {
                        filters: {
                            dateFrom: data.date_from,
                            dateTo: data.date_to,
                            filialList: data.filial_list,
                        },
                        reportData: {
                            statByDateData: data.stat_by_date_data,
                            detailedRecordTable: data.detailed_record_table,
                            adminsEfforts: data.admins_efforts,
                            adminsDayTable: data.admins_day_table,
                        },
                    }
                )
                this.dashboardData = newData.reportData
                const formatNumber = (num: number) => new Intl.NumberFormat('ru-RU').format(num)
                this.detailedTableData = this.dashboardData?.detailedRecordTable?.map(item => {
                    let clientName= item.client_name_with_num.split(' ')[0]
                    return {
                        clientName,
                        phone: item.client_phone,
                        visitDate: item.visit_date,
                        isOnline: item.is_online,
                        adminName: item.user_name,
                        hrefInfo: item.hrefInfo,
                        clientId: item.client_id,
                        userId: item.user_id,
                        userName: item.user_name,
                        originalProjectId: item.original_project_id,
                        creatingUserIdYc: item.creating_user_id_yc
                    }
                })
                this.adminsEffortsData = this.dashboardData?.adminsEfforts?.map((el: any) => ({
                    visitDate: el.visit_date,
                    numAdmDay: formatNumber(el.num_adm_day ?? 0),
                    attrUserName: el.attr_user_name,
                    effortsAdmins: formatNumber(el.efforts_admins ?? 0),
                    originalProjectId: el.original_project_id,
                    creatingUserIdYc: el.creating_user_id_yc,
                    attrUserId: el.attr_user_id,
                }))
                if (error) {
                    this.$store.dispatch('callNotify', 'Ошибка')
                }
                this.initialDetailedRecordTable = _.cloneDeep(newData.reportData.detailedRecordTable)
                this.initialAdminsEfforts = _.cloneDeep(newData.reportData.adminsEfforts)
                this.filters = Object.assign({}, this.filters, newData.filters)
                this.setFiltersLists()
                this.dashboardDataLoading = false
            } else {
                const res = await api.gcb2.getSameDayRecReportData({
                    projectId: this.project.id,
                    filters: this.queryFilters,
                })
                data = res.data
                error = res.error
                if (error) {
                    this.$store.dispatch('callNotify', 'Ошибка')
                }
                this.dashboardData = data.reportData
                this.filters = Object.assign({}, this.filters, data.filters)
                this.setFiltersLists()
                this.dashboardDataLoading = false
            }
        },
        formatDate(date: string) {
            return date ? new Date(date).toLocaleDateString() : ''
        },
        handleKeyDownEfforts(e) {
            // Разрешаем только: цифры, Backspace, Delete, Tab и Arrow keys
            const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'Home', 'End']

            if (!allowedKeys.includes(e.key) && isNaN(parseInt(e.key))) {
                e.preventDefault()
            }
        },
        getChatTextMessage(manualCommunication) {
            return messageVarsService.resolveVars(
                manualCommunication?.href_info?.filter(el => el?.connectorType === 'waLink')?.text || '',
                this.connector,
                manualCommunication
            )
        },
        setVideoHelepVisible(value) {
            this.showVideoHelp = value
        }
    },
    async mounted() {
        this.inititalFilters = Object.assign({}, this.filters)
        this.pageLoading = true
        this.setDefaultDates()
        this.projectType = await api.project
            .getType({ projectId: this.project.id })
            .then(res => res.data?.type)
        await this.initData(true)
        this.pageLoading = false
    },
})
